<template>
  <v-app-bar
    app
    color="green lighten-5"
    dark
  >
    <div class="d-flex align-center">
      <v-img
        alt="CALE-AI"
        class="shrink mr-2"
        contain
        src="@/assets/logo.png"
        transition="scale-transition"
        width="26"
      />
    <v-toolbar-title class="teal--text">CALE AI</v-toolbar-title>
    </div>
    <v-spacer></v-spacer>
      <v-btn text><router-link to="/">home</router-link> |</v-btn>
      <v-btn text><router-link to="/ethics">ethics</router-link> |</v-btn>
      <v-btn text><a href="https://diyhslab.medium.com/">blog</a> |</v-btn>
      <v-btn text><a href="https://codeberg.org/cale">code</a></v-btn>
      <!-- <v-btn color="brown lighten-3">JOIN</v-btn> -->
  </v-app-bar>
</template>

<script>
export default {
  name: 'MenuNavigation',
  data: () => ({
  })
}
</script>

<template>
  <v-container fluid fill-height>
    <div class="cale-waves">
      <cale-learn></cale-learn>
      <!-- <cale-ai></cale-ai> -->
      <gaia-theory></gaia-theory>
      <cale-features></cale-features>
      <cale-footer></cale-footer>
    </div>
  </v-container>
</template>

<script>
// import CaleAi from '@/components/caleAI'
import GaiaTheory from '@/components/gaiaTheory'
import CaleLearn from '@/components/caleLearn'
import CaleFeatures from '@/components/caleFeatures'
import CaleFooter from '@/components/caleFooter'

export default {
  name: 'CALE-Content',
  components: {
    // CaleAi,
    GaiaTheory,
    CaleLearn,
    CaleFeatures,
    CaleFooter
  }
}
</script>

<style>
.cale-waves {
  background-color: 'green lighten-5';
  background-position: center center;
  background-repeat: repeat-y;
  width: 100%;
  height: 100%;
}
</style>

<template>
  <v-container grid-list-lg>
    <v-layout row>
        <v-flex xs12 class="text-xs-center display-1 font-weight-black my-5 color green lighten-5">Participate</v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm6 md6>
        <v-card>
          <v-card-title primary-title>
            <div>
                <h3 class="headline mb-0 font-weight-bold">BentoBox-DS</h3>
                <div style="word-break: break-word" class="spacing-playground pa-3">
                  Download a <a href="https://bentobox.healthscience.network/">graphical toolkit</a> to contribute data on a bioregion or particpate in understanding the analysis.
                </div>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 md6>
        <v-card>
          <v-card-title primary-title>
            <div>
                <h3 class="headline mb-0 font-weight-bold">Visualisations</h3>
                <div style="word-break: break-word" class="spacing-playground pa-3">
                  The tools allow you to compare e.g. waterflow in different parts of a river, compare rainfall to flow to compare rivers regionally or across the world.
                </div>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm6 md6>
        <v-card>
          <v-card-title primary-title>
            <div>
                <h3 class="headline mb-0 font-weight-bold">Goldilocks Simulations</h3>
                <div style="word-break: break-word" class="spacing-playground pa-3">
                  Earth is said to be in the habitable or goldilocks zone, not too cold or too hot but just right for life.  In the modern world, growth graphs are good to be heading top right and ideally in an exponential curve. Our simulation are based on goldilocks design thinking.
                </div>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 md6>
        <v-card>
          <v-card-title primary-title>
            <div>
                <h3 class="headline mb-0 font-weight-bold">CALE AI</h3>
                <div style="word-break: break-word" class="spacing-playground pa-3">
                 Coming soon. A personal AI agent that performs evolutionary computing to help build models that better understand our climate and biodiversity.
                </div>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'CALE-learn',
  data: () => ({
    model: 0
  }),
  methods: {
  }
}
</script>

<style scoped>
.open-left {
  margin-top: 3em;
  perspective: 1000px;
}

.left-perspective {
  transform: rotateY(14deg);
}

.open-right {
  perspective: 1000px;
  filter: drop-shadow(10px 20px 55px #B2DFDB);
}

.right-perspective {
  transform: rotateY(-14deg);
}

.middle-top {
  margin-top: 200px;
}
</style>

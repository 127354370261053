<template>
  <v-container grid-list-lg>
    <v-layout row>
        <v-flex xs12 class="text-xs-center display-1 font-weight-black my-5 color green lighten-5">Gaia Theory</v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm12 md12>
        <v-card>
          <v-card-title primary-title>
            <div>
                <h3 class="headline mb-0 font-weight-bold">James Lovelock</h3>
                <div style="word-break: break-word" class="spacing-playground pa-3">
                  In the 1970's <a href="https://en.wikipedia.org/wiki/Gaia_hypothesis">Gaia Theory</a> was put forward by <a href="https://en.wikipedia.org/wiki/James_Lovelock">James Lovelock</a>. A theory that postulates earth is a self regulating system.  To demonstrate this Lovelock and collaborators built a black and white daisy planet - star(sun) simulation known as <a href="https://en.wikipedia.org/wiki/Daisyworld">daisy world</a>.  The original model has been built upon and the mission of this project is to produce a 21 centuary update to the simulation peer to peer.
                </div>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'CALE-learn',
  data: () => ({
    model: 0
  }),
  methods: {
  }
}
</script>

<style scoped>
.open-left {
  margin-top: 3em;
  perspective: 1000px;
}

.left-perspective {
  transform: rotateY(14deg);
}

.open-right {
  perspective: 1000px;
  filter: drop-shadow(10px 20px 55px #B2DFDB);
}

.right-perspective {
  transform: rotateY(-14deg);
}

.middle-top {
  margin-top: 200px;
}
</style>

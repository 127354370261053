<template>
  <v-container grid-list-lg>
    <v-layout row>
        <v-flex xs12 class="text-xs-center display-1 font-weight-black my-5 color green lighten-5">Privacy</v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm12 md12>
        <v-card>
          <v-card-title primary-title>
            <div>
                <h3 class="headline mb-0 font-weight-bold">Control - Sovereignty - Privacy</h3>
                <div style="word-break: break-word" class="spacing-playground pa-3">
                  CALE AI is a personal AI and is under each peers control, sovereignty and privacy settings.
                </div>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'CALE-learn',
  data: () => ({
    model: 0,
    items: [
      {
        src: require('@/assets/bb-bioregion.png')
      },
      {
        src: require('@/assets/lifeflow.png')
      }
    ]
  })
}
</script>

<style scoped>
.open-left {
  margin-top: 3em;
  perspective: 1000px;
}

.left-perspective {
  transform: rotateY(14deg);
}

.open-right {
  perspective: 1000px;
}

.right-perspective {
  transform: rotateY(-14deg);
}
</style>
